import React, { useState, useEffect, Fragment } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Dropdown, Menu, Modal, notification, Button,Space,Input, Form, Row, Col, Collapse, message, Spin } from "antd";
import { LoadingOutlined,CloseOutlined } from "@ant-design/icons";
import { getProcessParamData, getRunProcess, getRunProcessWithoutParameters, getProcessParamJson } from "../../services/generic";
import { useGlobalContext, useWindowContext } from "../../lib/storage";
import ProcessField from "./ProcessField";
import "antd/dist/antd.css";
import "../../styles/antd.css"
import useDebounce from "../../lib/hooks/useDebounce";
import "./style.css"
import axios from "axios";
import { useParams } from "react-router";
import dayjs from "dayjs";
import ThemeJson from "../../constants/UIServer.json"
import RecordTable from "./RecordTable";
import DownArrow from "../../assets/images/actionsIcon.svg";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const { Panel } = Collapse;

const RecordTitle = (props) => {
  const { globalStore } = useGlobalContext();
  const Themes = ThemeJson;
  const { setLastRefreshed,setTabLastRefreshed, headerRecordData, isHeaderActive,recordTitle1,flag,tabRecord,setButtonsFlag,setLoadingRecordWindow } = props;
  const { recordId } = useParams();
  const { windowStore } = useWindowContext();
  const windowDefinition = { ...windowStore.windowDefinition };
  const [buttonFieldsGroup, setButtonFieldsGroup] = useState([]);
  const [soloButtons, setSoloButtons] = useState([]);
  const [soloWithPartnerButtons, setSoloWithPartnerButtons] = useState([]);
  const [headerTabId, setHeaderTabId] = useState("");
  const [visible, setVisible] = React.useState(false);
  const [formFields, setFormFields] = useState([]);
  const [formLineFields, setFormLineFields] = useState([]);
  const [processParamsData, setProcessParamsData] = useState({});
  const [idForRunProces, setIdForRunProces] = useState([]);
  const [multiProcessFlag, setMultiProcessFlag] = useState([]);
  const [titleButtonProcess, setTitleButtonProcess] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [selectedRecordsData, setSelectedRecordsData] = useState({});
  const [reload,setReload] = useState(false)
  const [processId,setProcessId] = useState(null)
  const fileDownloadUrl = process.env.REACT_APP_fileDownloadUrl;
  const [propsConfirmModalVisible, setPropsConfirmModalVisible] = useState(false);
  const [propsConfirmModalContent, setPropsConfirmModalContent] = useState(null);
  const [propsConfirmModalTitle, setPropsConfirmModalTitle] = useState(null);
  const [propsConfirmOkParams, setPropsConfirmOkParams] = useState({ processData: null, key: null, withoutPara: null, id: null,isMultiProcess: null });

  useEffect(() => {
    headerDataFetch();
  }, [headerRecordData,flag]);

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  const headerDataFetch = async () => {
    // console.log(flag,"-----flag")
    try {
      if (windowDefinition.tabs) {
        const level = flag ? "1" : "0"; // Determine the level based on the flag
        const headerTabData = windowDefinition.tabs.find((tab) => tab.tablevel === level);
        
        if (!headerTabData) return; // Exit if no matching tab found
  
        const headerTabId = headerTabData.ad_tab_id;
  
        // Sort the fields based on `seqno`
        headerTabData.fields.sort((a, b) => {
          const x = a.seqno !== null ? parseInt(a.seqno) : Infinity;
          const y = b.seqno !== null ? parseInt(b.seqno) : Infinity;
          return x - y;
        });
  
        // Filter buttons with base reference id "28"
        const newFieldGroupsList = headerTabData.fields.filter(element => element.nt_base_reference_id === "28");
        const filteredButtons = newFieldGroupsList.filter(checkDisplayLogic);
        // Handle buttons based on flag
        // console.log(newFieldGroupsList)
        // console.log(filteredButtons)
        if (flag) {
          // console.log("------>",flag,buttonFieldsGroup)
          if (soloButtons.length>0) {
            // If there's a solo button, remove it from soloButtons and add both to buttonFieldsGroup
            setButtonFieldsGroup([...buttonFieldsGroup, soloButtons, ...newFieldGroupsList]);
            setSoloButtons(null);
          } else if (buttonFieldsGroup.length > 0) {
            // If no solo button but buttonGroup exists, add the first of new buttons to soloButtons
            // setSoloButtons(newFieldGroupsList[0]);
            setButtonFieldsGroup([...buttonFieldsGroup, ...newFieldGroupsList]);
          } else {
            // No solo button and no buttonGroup
            if (newFieldGroupsList.length === 1 && buttonFieldsGroup.length === 0) {
              setSoloButtons(newFieldGroupsList[0]); // Set the only button as solo
            } else {
              setButtonFieldsGroup(newFieldGroupsList); // Set all buttons to buttonFieldsGroup
            }
          }
        } else {
          if (newFieldGroupsList.length === 1) {
            // If only one button, set it as solo
            setSoloButtons(newFieldGroupsList[0]);
          } else {
            if(filteredButtons.length === 1){
              setSoloButtons(filteredButtons[0])
              setButtonFieldsGroup([])
            }else{
               setSoloButtons([])
              //  console.log("------buu")
               // Otherwise, add all to buttonFieldsGroup
            setButtonFieldsGroup(newFieldGroupsList);
            }
           
          }
        }
        const hasButtons = buttonFieldsGroup.length > 0 || soloButtons.length !== 0;
        setButtonsFlag(hasButtons);
        // Set the header tab ID
        setHeaderTabId(headerTabId);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  
  const handleMenuClickForSingle = async (data) => {
    if (isHeaderActive) {
      message.warn("Save Record!");
      return;
    }
  
    const {
      ad_field_id: adFieldId,
      processtype,
      nt_process_id: ntProcessId,
      isrequiredconfirmationforprocess: isReqConfirm,
      enablemultiprocess: isMultiProcess,
    } = data;
    setProcessId(ntProcessId)
    setIdForRunProces(adFieldId);
    setMultiProcessFlag(isMultiProcess);
    const level = flag ? "1" : "0";
    const headerTabData = windowDefinition.tabs.find((tab) => tab.tablevel === level);
    const configurations = headerTabData?.configurations;
    const relevantConfig = configurations?.find(config => adFieldId === config.button_field_id);
  
    let conditionMet = true; // Default to true if there's no condition to check
  
    if (relevantConfig) {
      const fieldValue = headerRecordData[relevantConfig.field_ids];
  
      switch (relevantConfig.criteria) {
        case "Greater than":
          conditionMet = fieldValue > relevantConfig.value;
          break;
        case "Less than":
          conditionMet = fieldValue < relevantConfig.value;
          break;
        case "Equal to":
          conditionMet = fieldValue === relevantConfig.value;
          break;
        case "Not equal to":
          conditionMet = fieldValue !== relevantConfig.value;
          break;
        case "Greater than or equal to":
          conditionMet = fieldValue >= relevantConfig.value;
          break;
        case "Less than or equal to":
          conditionMet = fieldValue <= relevantConfig.value;
          break;
        default:
          console.warn("Unknown criteria:", relevantConfig.criteria);
      }
    }
  
    try {
      if (isReqConfirm === "Y") {
        // If confirmation is required, check the condition
        if (relevantConfig && !conditionMet) {
          // Condition is not satisfied, directly process without confirmation
          callFinalProcess(adFieldId, isMultiProcess);
        } else {
          // Condition is satisfied or no condition exists, show confirmation
          const btnProcessData = await getProcessParamJson(adFieldId, processtype, ntProcessId, isReqConfirm);
          const withoutPara = !btnProcessData["parameters"];
          showPropsConfirm(btnProcessData, adFieldId, withoutPara, ntProcessId, isMultiProcess);
        }
      } else {
        // No confirmation required, directly process
        callFinalProcess(adFieldId, isMultiProcess);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
    
  const handleMenuClick = async (e) => {
    if (isHeaderActive) {
      message.warn("Save Record!");
      return;
    }

    const level = flag ? "1" : "0"; // Determine the level based on the flag
    const headerTabData = windowDefinition.tabs.find((tab) => tab.tablevel === level);
    const { processtype, processid: ntProcessId, isreqconfirm: isReqConfirm, enablemultiprocess: isMultiProcess } = e["item"]["props"];
    const adFieldId = e.key;

    setIdForRunProces(adFieldId);
    setMultiProcessFlag(isMultiProcess);

    const configurations = headerTabData.configurations;
    const headerRecordDataforButton = !flag ? headerRecordData : tabRecord ? tabRecord[0] : null;
    
    const conditionalPopupConfig = configurations?.find(config => config.type === "Conditional Popup" && e.key === config.button_field_id);
    
    if (isReqConfirm === "Y" && conditionalPopupConfig) {
      const fieldValue = headerRecordDataforButton[conditionalPopupConfig.field_ids];
      const conditionMet = checkCondition(fieldValue, conditionalPopupConfig.criteria, conditionalPopupConfig.value);

      if (conditionMet) {
        await processAction(adFieldId, processtype, ntProcessId, isReqConfirm, isMultiProcess);
      } else {
        callFinalProcess(adFieldId, isMultiProcess,processtype);
      }
    } else {
      await processAction(adFieldId, processtype, ntProcessId, isReqConfirm, isMultiProcess);
    }
  };


  
  
  const checkCondition = (fieldValue, criteria, value) => {
    switch (criteria) {
      case "Greater than":
        return fieldValue > value;
      case "Less than":
        return fieldValue < value;
      case "Equal to":
        return fieldValue === value;
      case "Not equal to":
        return fieldValue !== value;
      case "Greater than or equal to":
        return fieldValue >= value;
      case "Less than or equal to":
        return fieldValue <= value;
      default:
        console.warn("Unknown criteria:", criteria);
        return false;
    }
  };
  
  
  const processAction = async (adFieldId, processtype, ntProcessId, isReqConfirm, isMultiProcess) => {
    try {
      if (isReqConfirm === "Y") {
        const btnProcessData = await getProcessParamJson(adFieldId, processtype, ntProcessId, isReqConfirm);
        const withoutPara = !btnProcessData["parameters"];
        showPropsConfirm(btnProcessData, adFieldId, withoutPara, ntProcessId, isMultiProcess,processtype);
      } else {
        callFinalProcess(adFieldId, isMultiProcess,processtype);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  

  const showPropsConfirm = async (processData, key, withoutPara, id,isMultiProcess,processtype) => {
    let processDataName = processData.name;
    processDataName = processDataName.split("-").pop();
    getParamsData(id);
    setPropsConfirmModalVisible(true);
    setPropsConfirmModalContent(processDataName);
    setPropsConfirmModalTitle(processDataName?.replace(" From Sales Order", ""))
    setPropsConfirmOkParams({ processData: processData, key: key, withoutPara: withoutPara, id: id,isMultiProcess:isMultiProcess,processtype:processtype });
        /* Modal.confirm({
      title: "Add New",
      content: `Do you want to ${processDataName}?`,
      okText: "Confirm",
      cancelText: "Cancel",
      icon:null,
      onOk() {
        if (withoutPara === true) {
          callFinalProcess(key);
        } else {
          let parameterArrayForGrid = [];
          let parameterArray = [];
          let btnProcessData = processData.parameters;
          for (let i = 0; i < btnProcessData.length; i += 1) {
            if (btnProcessData[i].type === "Form") {
              parameterArrayForGrid.push(btnProcessData[i]);
            } else {
              parameterArray.push(btnProcessData[i]);
            }
          }
          setTitleButtonProcess(processData.name);
          for (let index = 0; index < parameterArray.length; index++) {
            parameterArray[index]["ad_field_id"] = parameterArray[index].column_name;
            parameterArray[index]["isreadonly"] = parameterArray[index].readonly;
            parameterArray[index]["column_type"] = parameterArray[index].type;
            parameterArray[index]["name"] = parameterArray[index].display_name;
          }

          setFormFields(parameterArray);

          setFormLineFields(parameterArrayForGrid);

          setVisible(true);
        }
      },
      onCancel() {},
    }); */
  };

  const propsConfirmOk = () => {
    const propsOkData = { ...propsConfirmOkParams };
    const { processData, withoutPara, key, id,isMultiProcess,processtype } = propsOkData;
    if (withoutPara === true) {
      callFinalProcess(key,isMultiProcess,processtype);
      setPropsConfirmModalVisible(false);
    } else {
      setVisible(true);
      setPropsConfirmModalVisible(false);
      let parameterArrayForGrid = [];
      let parameterArray = [];
      let btnProcessData = processData.parameters;
      for (let i = 0; i < btnProcessData.length; i += 1) {
        if (btnProcessData[i].type === "Form") {
          parameterArrayForGrid.push(btnProcessData[i]);
        } else {
          parameterArray.push(btnProcessData[i]);
        }
      }
      setTitleButtonProcess(processData.name);
      for (let index = 0; index < parameterArray.length; index++) {
        parameterArray[index]["ad_field_id"] = parameterArray[index].column_name;
        parameterArray[index]["isreadonly"] = parameterArray[index].readonly;
        parameterArray[index]["column_type"] = parameterArray[index].type;
        parameterArray[index]["name"] = parameterArray[index].display_name;
      }

      setFormFields(parameterArray);

      setFormLineFields(parameterArrayForGrid);

      
    }
  };

  const propsConfirmCancel = () => {
    setPropsConfirmModalVisible(false);
  };

  const updateLastRefresh = () => {
    setLastRefreshed(new Date());
  };

  // useEffect(()=>{
  //   if(reload){
  //     getParamsData(processId)
  //   }
  // },[reload])

  const getParamsData = async (key) => {
    try {
      const processBtnFormData = await getProcessParamData(windowDefinition.ad_window_id,headerTabId, recordId, key);
      setProcessParamsData(processBtnFormData);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const getPrintDownloadData = async (fileName) => {
    const printTemplateData = await axios({
      url: `${fileName}`,
      method: "GET",
      responseType: "blob",
    });
    return printTemplateData;
  };

  const callFinalProcess = async (adFieldIdForRunProces,multiProcess,processtype) => {
    
    try {
      let recordIdfor ;
      if(flag){
        
        recordIdfor = tabRecord[0].recordId
      }else{
        recordIdfor =recordId
      }
      setLoadingRecordWindow(true)
      const callRunProcessWithoutParaData = await getRunProcessWithoutParameters(adFieldIdForRunProces, headerTabId, recordIdfor,windowDefinition.ad_window_id,multiProcess);

      const messageCode = callRunProcessWithoutParaData.messageCode;
      const Title = callRunProcessWithoutParaData.title;
      const Message = callRunProcessWithoutParaData.message;
      const path = callRunProcessWithoutParaData.downloadURL;
      if (messageCode === "200") {
        
        if(processtype === "Export"){
        // const downloadFile = async (fileName, path) => {
          const downloadPrintData = await getPrintDownloadData(path);
          const fileURL = window.URL.createObjectURL(new Blob([ downloadPrintData.data]));
          const link = document.createElement("a");
          link.setAttribute("id", "downloadlink");
          link.href = path;
          link.setAttribute("download","CashUpReport");
          link.click();
        }else{
          notification.success({
            message: Title,
            description: Message,
          });
        }
        updateLastRefresh();
      } else if (messageCode === "201") {
        notification.info({
          message: Title === undefined || Title === "undefined" ? "Please check Once" : Title,
          description: Message === undefined || Message === "undefined" ? "Please check Once" : Message,
        });
      } else {
        notification.error({
          message: Title === undefined || Title === "undefined" ? "Please check Once" : Title,
          description: Message === undefined || Message === "undefined" ? "Please check Once" : Message,
        });
      }
    } catch (error) {
      console.error("Error", error);
    }
    setLoadingRecordWindow(false)
  };

  const handleOk = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (error) {
    }
  };

  const handleCancel = () => {
    //datRows.current = selectedRowKeys;
    setVisible(false);
    setSelectedRecordsData({});
  };

  useEffect(() => {
    if (!visible) {
      setSelectedRecordsData({});
    }
  }, [visible]);

  const onFinish = async (values) => {
    setLoadingModal(true);
    Object.entries(values).map(([key, value]) => {
      if (value === true) {
        values[key] = "Y";
      }
      if (value === false) {
        values[key] = "N";
      }
      if (typeof value === "number") {
        values[key] = `${value}`;
      }
      if (dayjs.isDayjs(value)) {
        values[key] = `${value.format("YYYY-MM-DD HH:mm:ss")}`;
      }
      if (value === "") {
        values[key] = null;
      }
      if (value === undefined) {
        values[key] = null;
      }
      return null;
    });

    let getAllTablesValues = {};
    Object.keys(processParamsData).forEach(function (key) {
      let valObj = processParamsData[key];
      if (typeof valObj === "object" && valObj !== null) {
        getAllTablesValues[key] = valObj;
      }
    });

    let mandatoryViolated = false;
    Object.entries(getAllTablesValues).forEach(([aKey, aValue]) => {
      const fieldParamJson = formLineFields[formLineFields.findIndex((ffl) => ffl.column_name === aKey)];
      aValue.forEach((bObj, bIndex) => {
        Object.entries(bObj).forEach(([cKey, cValue]) => {
          if (cValue === true) {
            getAllTablesValues[aKey][bIndex][cKey] = "Y";
          }
          if (cValue === false) {
            getAllTablesValues[aKey][bIndex][cKey] = "N";
          }
          if (typeof cValue === "number") {
            getAllTablesValues[aKey][bIndex][cKey] = `${cValue}`;
          }
          if (dayjs.isDayjs(cValue)) {
            getAllTablesValues[aKey][bIndex][cKey] = `${cValue.format("YYYY-MM-DD HH:mm:ss")}`;
          }
          if (cValue === "") {
            getAllTablesValues[aKey][bIndex][cKey] = null;
          }
          if (cValue === undefined) {
            getAllTablesValues[aKey][bIndex][cKey] = null;
          }

          if (!cValue) {
            const fieldParamJsonIndex = fieldParamJson.fields.findIndex((ffl) => ffl.field_name === cKey);
            if (fieldParamJsonIndex >= 0 && fieldParamJson.fields[fieldParamJsonIndex].mandatory === "Y") {
              mandatoryViolated = true;
            }
          }
        });
      });
    });

    if (!mandatoryViolated) {
      let completeData = Object.assign({}, values, getAllTablesValues);
      Object.entries(selectedRecordsData).map(([paramkey, paramrows]) => {
        let newParamData = [];
        paramrows.map((key) => {
          return newParamData.push(completeData[paramkey][parseInt(key)]);
        });
        return (completeData[paramkey] = newParamData);
      });

      let NewCompleteData = JSON.stringify(JSON.stringify(completeData));
      
      try {
  
        const runProcess = await getRunProcess(idForRunProces, headerTabId, recordId,windowDefinition.ad_window_id, NewCompleteData);

        const messageCode = runProcess.messageCode;
        const Title = runProcess.title;
        const Message = runProcess.message;
        if (messageCode === "200") {
          setTimeout(() => {
            setVisible(false);
            setLoadingModal(false);
            notification.success({
              message: Title,
              description: Message,
            });
          }, 2000);
          setLastRefreshed(new Date());
          setTabLastRefreshed(new Date());
        } else if (messageCode === "201") {
          notification.info({
            message: Title === undefined || Title === "undefined" ? "Please check Once" : Title,
            description: Message === undefined || Message === "undefined" ? "Please check Once" : Message,
          });
          setLoadingModal(false);
        } else {
          notification.error({
            message: Title === undefined || Title === "undefined" ? "Please check Once" : Title,
            description: Message === undefined || Message === "undefined" ? "Please check Once" : Message,
          });
          setLoadingModal(false);
        }
      } catch (error) {
        console.error("Error", error);
        setLoadingModal(false);
      }
    } else {
      message.warning("Please input proper values !");
    }
  };

  const [form] = Form.useForm();

  const handleSave = (row) => {
    let processLocalData = processParamsData;

    const newData = processLocalData[row["tableName"]];

    const index = newData.findIndex((item) => row.key === item.key);

    const item = newData[index];

    newData.splice(index, 1, { ...item, ...row });

    let tempTableData = processLocalData[row["tableName"]];
    let tempTableName = row["tableName"];

    setProcessParamsData({
      ...processParamsData,
      [tempTableName]: [...tempTableData],
    });
  };

  const checkDisplayLogic = (field) => {
    if (field?.displaylogic) {
      let string = field.displaylogic;
      const keys = string.split("@");
      const actualKeys = keys.filter((s) => s.length === 32);
      actualKeys.map((k) => {
        let actualDataValue = headerRecordData[k];
        if (typeof actualDataValue === "string" && isNaN(actualDataValue)) {
          actualDataValue = `'${actualDataValue}'`;
        }
        if (typeof actualDataValue === "boolean") {
          if (actualDataValue) {
            actualDataValue = `'Y'`;
          } else {
            actualDataValue = `'N'`;
          }
        }
        const actualData = actualDataValue;
        const stringToUpdate = "@" + k + "@";
        return (string = string.replaceAll(stringToUpdate, actualData));
      });

      string = string.replaceAll("=", "==");
      string = string.replaceAll("<==", "<=");
      string = string.replaceAll(">==", ">=");
      string = string.replaceAll("&", "&&");
      string = string.replaceAll("|", "||");
      string = string.replaceAll("====", "===");
      string = string.replaceAll("&&&&", "&&");
      string = string.replaceAll("||||", "||");

      let logicState;
      try {
        // eslint-disable-next-line
        logicState = eval(string);
      } catch (error) {
        console.error("Invalid Display Logic Condition: ", string);
        logicState = false;
      }

      return logicState;
    } else {
      return true;
    }
  };



  const menu = (
    <Col  style={{ height: "auto" }}>
      <Row>
        <Menu className="dropMenu" onClick={handleMenuClick}>
          {buttonFieldsGroup.map((field) => {
            return checkDisplayLogic(field) ? (
              <Menu.Item style={{height:"30px",lineHeight:'25px'}} key={field.ad_field_id} isreqconfirm={field.isrequiredconfirmationforprocess} processid={field.nt_process_id} processtype={field.processtype}>
                {field.name}
              </Menu.Item>
            ) : null;
          })}
        </Menu>
      </Row>
    </Col>
  );
  const hasMenuItems = buttonFieldsGroup.some((field) => checkDisplayLogic(field));

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
      <Space style={{marginBottom:'5px'}}>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
        >
          Reset
        </Button>
        <Button
          onClick={() => {
            confirm({ closeDropdown: true });
            // document.getElementById("filterText").value = "";
            handleReset(clearFilters)
          }}
          size="small"
        >
          Ok
        </Button>
      </Space>
      <br/>
        <Input
          value={selectedKeys[0]}
          onChange={e => { setSelectedKeys(e.target.value ? [e.target.value] : []) }}
          style={{ marginBottom: 3, width: 200 }}
        />
                
    </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });

  const batchSize = 30; // Load 30 records initially
  const [displayedData, setDisplayedData] = useState({}); // Track displayed records for each column
  // const [loadingModal, setLoadingModal] = useState(false); // Spinner state

  useEffect(() => {
    if (visible) {
      // Initially load the first batch of records when the modal is visible
      setLoadingModal(true);
      const initialDisplayedData = {};

      let hasRecords = false; // Track if there are any records to load

      formLineFields.forEach((collaps) => {
        const columnData = processParamsData[collaps.column_name] || [];
        if (columnData.length > 0) {
          hasRecords = true; // Indicate that there is at least one record
        }
        initialDisplayedData[collaps.column_name] = columnData.slice(0, batchSize); // Load initial batch
      });

      setDisplayedData(initialDisplayedData);

      if (hasRecords) {
        // Load remaining records in a progressive manner after the modal is opened
        setTimeout(() => {
          loadRemainingRecords();
        }, 500); // Delay to simulate modal being opened before loading remaining data
      } else {
        // No records, so stop the loading spinner immediately
        setLoadingModal(false);
      }
    }
  }, [visible, processParamsData]);

  // Function to load remaining records in batches
  const loadRemainingRecords = () => {
    formLineFields.forEach((collaps) => {
      const columnData = processParamsData[collaps.column_name] || [];
      const totalRecords = columnData.length;

      if (totalRecords > batchSize) {
        const remainingData = columnData.slice(batchSize);

        // Progressively load the remaining data
        setTimeout(() => {
          setDisplayedData((prev) => ({
            ...prev,
            [collaps.column_name]: [...prev[collaps.column_name], ...remainingData]
          }));
          setLoadingModal(false); // Hide spinner once all data is loaded
        }, 500); // Delay to simulate progressive loading
      } else {
        setLoadingModal(false); // Hide spinner if no additional data is needed
      }
    });
  };

  const [searchInput, setSearchInput] = useState(""); // Input value
  // const [filteredData, setFilteredData] = useState(dataSource); // Filtered table data
  const debouncedSearchInput = useDebounce(searchInput, 500); // Debounced input value

  useEffect(() => {
    if (!debouncedSearchInput) {
      // Reset to the original displayed data when the input is empty
      const initialDisplayedData = {};
      formLineFields.forEach((collaps) => {
        initialDisplayedData[collaps.column_name] =
          processParamsData[collaps.column_name]?.slice(0, batchSize) || [];
      });
      setDisplayedData(initialDisplayedData);
      return;
    }
  
    const lowercasedInput = debouncedSearchInput.toLowerCase();
  
    const filteredData = {};
    formLineFields.forEach((collaps) => {
      const columnData = processParamsData[collaps.column_name] || [];
      filteredData[collaps.column_name] = columnData.filter((record) =>
        Object.values(record).some((value) =>
          String(value).toLowerCase().includes(lowercasedInput)
        )
      );
    });
  
    setDisplayedData(filteredData);
  }, [debouncedSearchInput, processParamsData]);
  

  return (
    <Fragment>
      {recordId !== "NEW_RECORD" ? (
        <div style={{marginLeft:'4px'}}>
          {/* <Col {...responsiveDesignSpace} /> */}
          <Col style={{ float: "right",marginTop:"0px"}}>
            {soloButtons.length !== 0  ? (
              checkDisplayLogic(soloButtons) ? (
                <span style={{marginLeft:'10px'}}>
                <Button type="primary" style={Themes.contentWindow.recordWindow.RecordHeader.formViewButton.formTopButtons} onClick={() => handleMenuClickForSingle(soloButtons)}>
                  {soloButtons.name}
                </Button>
                </span>
              ) : null
            )
            //  : buttonFieldsGroup.length === 2 ? (
            //   <span>
            //     {checkDisplayLogic(soloButtons) ? (
            //       <Button type="primary" style={Themes.contentWindow.recordWindow.RecordHeader.formViewButton.formTopButtons} onClick={() => handleMenuClickForSingle(soloButtons)}>
            //         {soloButtons?.name}
            //       </Button>
            //     ) : null}
            //     &nbsp;
            //     {checkDisplayLogic(soloWithPartnerButtons) ? (
            //       <Button type="primary" style={Themes.contentWindow.recordWindow.RecordHeader.formViewButton.formTopButtons} onClick={() => handleMenuClickForSingle(soloWithPartnerButtons)}>
            //         {soloWithPartnerButtons.name}
            //       </Button>
            //     ) : null}
            //   </span>
            // ) 
            : (
              <span>
                {soloButtons && soloButtons.length > 0 && checkDisplayLogic(soloButtons) ? (
                  <Button
                    type="primary"
                    style={Themes.contentWindow.recordWindow.RecordHeader.formViewButton.formTopButtons}
                    onClick={() => handleMenuClickForSingle(soloButtons)}
                  >
                    {soloButtons.name}
                  </Button>
                ) : null}
                &nbsp;
                {hasMenuItems && (
                  <Dropdown trigger={["click"]} overlay={menu}>
                    <Button
                      type="primary"
                      style={Themes.contentWindow.recordWindow.RecordHeader.formViewButton.formTopButtons}
                    >
                      Actions&nbsp;&nbsp;
                      <img style={{ width: "12px" }} src={DownArrow} preview={false} />
                    </Button>
                  </Dropdown>
                )}
              </span>
            )}
          </Col>
        </div>
      ) : null}
      <div>
        <Modal
          title={<>
            <center style={{fontWeight:'bold',width:'40%px',fontStyle:'normal'}}>{titleButtonProcess}</center>
            <span style={{float:'right',marginTop:"-25px"}}><CloseOutlined onClick={handleCancel}/></span>
            </>}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          centered
          width="1200px"
          maskClosable={false}
          destroyOnClose={true}
          footer={[
            <span
             style={{color:Themes.appTheme.primaryColor,fontWeight:700,cursor:'pointer'}}
              onClick={handleCancel}
            >
              Cancel
            </span>,
            <Button style={{ backgroundColor:Themes.appTheme.primaryColor, color: "white", width: "88px", height: "36px",marginLeft:'31px',fontWeight:700,borderRadius:'4px'}} loading={false} onClick={handleOk}>
              Confirm
            </Button>,
          ]}
        >
          <Spin indicator={<LoadingOutlined className="spinLoader" style={{ marginLeft: "auto", fontSize: "52px" }} spin />} spinning={loadingModal}>
            <Scrollbars
              style={{
                height: "60vh",
              }}
              autoHide
              // Hide delay in ms
              autoHideTimeout={1000}
              // Duration for hide animation in ms.
              autoHideDuration={200}
              thumbSize={90}
              renderView={renderView}
              renderThumbHorizontal={renderThumb}
              renderThumbVertical={renderThumb}
            >
              <Form
                style={{ paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px" }}
                form={form}
                preserve={false}
                name="processBtnForm"
                layout="vertical"
                onFinish={onFinish}
              >
                <Row gutter={[24, 24]}>
                  <Col span={8}>
                    {/* <Form.Item  label="Search Records" shouldUpdate> */}
                    <label  style={{ fontSize:12}}>Search Records</label>

                      <Input
                        placeholder="Search records"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        style={{ borderRadius:"5px", marginTop:"4px"}}
                      />
                    {/* </Form.Item> */}
                  </Col>
                  {formFields.map((field, index) => {
                    return field.displayed === "Y" ? (
                       <Col key={`${index}-${formFields["parameter_id"]}`} span={8}>
                        <ProcessField windowId={windowDefinition.ad_window_id} headerTabId={headerTabId} field={field} form={form} fieldData={processParamsData} setProcessParamsData={setProcessParamsData} recordId={recordId} setReload={setReload}/>
                      </Col>
                    ) : null;
                  })}
                </Row>
                <div style={{ paddingTop: "24px" }} />
                <div style={Themes.contentWindow.recordWindow.RecordHeader.formViewButton.actionButtonMenu}>
          
                  {formLineFields.map((collaps) => {
                    
                    let processParams = processParamsData[collaps.column_name];

                    if (processParams) {
                      for (let index = 0; index < processParams.length; index++) {
                        processParams[index]["tableName"] = collaps.column_name;
                        processParams[index]["key"] = index.toString();
                      }
                    }

                    let colFields = collaps.fields;
                    const tableColumns = [];

                    const sortCollapsFields = colFields.sort(function (a, b) {
                      return a.sequenceno - b.sequenceno;
                    });

                    for (let index = 0; index < sortCollapsFields.length; index++) {
                      sortCollapsFields[index]["mainKey"] = index;
                      if (sortCollapsFields[index]["displayed"] === "Y") {
                        if (sortCollapsFields[index]["type"] === "Selector") {
                          tableColumns.push({
                            title: sortCollapsFields[index]["name"],
                            dataIndex: sortCollapsFields[index]["field_name"],
                            editable: sortCollapsFields[index]["readonly"] === "Y" ? false : true,
                            typeCol: true,
                            mainKey: index,
                          });
                        } else {
                          tableColumns.push({
                            title: sortCollapsFields[index]["name"],
                            dataIndex: sortCollapsFields[index]["field_name"],
                            editable: sortCollapsFields[index]["readonly"] === "Y" ? false : true,
                            typeCol: false,
                            mainKey: index,
                          });
                        }
                      }
                    }

                    const columns = tableColumns.map((col) => {
                      if (!col.editable) {
                        if (col.typeCol) {
                          col.dataIndex = col.dataIndex.concat("_iden");
                        }
                        // Add filter condition for non-editable columns
                        return {
                          ...col,
                          ...getColumnSearchProps(col.dataIndex),
                        };
                      }

                      const colDataValues = sortCollapsFields[sortCollapsFields.findIndex((a) => a.mainKey === col.mainKey)];
                      if (colDataValues.readonly === "Y") {
                        if (col.typeCol) {
                          col.dataIndex = col.dataIndex.concat("_iden");
                        }
                        return col;
                      }

                      return {
                        ...col,
                        onCell: (record, rowIndex) => ({
                          record,
                          rowIndex: rowIndex,
                          editable: col.editable,
                          dataIndex: col.dataIndex,
                          title: col.title,
                          colData: colDataValues,
                          handleSave: handleSave,
                          //setSelectedRowKeys: setSelectedRowKeys,
                          //selectedRowKeys: selectedRowKeys
                        }),
                      };
                    });

                    return (
                      <div style={{ paddingTop: "12px" }}>
                        <Collapse defaultActiveKey={['yourPanelKey']}>
                          <Panel header={collaps.display_name} key="yourPanelKey">
                            <RecordTable
                             dataSource={displayedData[collaps.column_name] || []} 
                              columns={columns}
                              selectedRecordsData={selectedRecordsData}
                              setSelectedRecordsData={setSelectedRecordsData}
                            />
                          </Panel>
                        </Collapse>
                      </div>
                    );
                  })}
                </div>
                <div style={{ paddingTop: "0px" }} />
              </Form>
            </Scrollbars>
          </Spin>
        </Modal>

        <Modal
         className="actionsModal"
          title={<span style={{fontFamily:"Inter",fontWeight:600,fontSize:"20px",float:"left"}}>{propsConfirmModalTitle}?</span>}
          maskClosable={false}
          destroyOnClose={true}
          visible={propsConfirmModalVisible}
          footer={null}
          closable={false}
          style={{ marginTop: "4%",borderRadius:"1em" }}
          bodyStyle={{height:"auto" ,borderRadius:"8px !important",paddingBottom:"2px"}}
          width="350px"
          // onOk={propsConfirmOk}
          onCancel={propsConfirmCancel}
        >
          <span style={{paddingTop:"0.7em",fontFamily:"Inter",fontWeight:400,fontSize:"13px"}}>{propsConfirmModalContent}{" "}#{recordTitle1}</span>
          <br />
          {/* <p style={{paddingTop:"1em",fontFamily:"Inter",fontWeight:400,fontSize:"13px"}}><Checkbox/>{" "}Stop showing confirmation dialog for "{propsConfirmModalTitle}"</p> */}
          <center style={{paddingTop:"1.5em",justifyContent:"space-around"}}>
            <Button style={{ fontFamily:"Roboto",fontWeight:700,cursor:"pointer",width:"48%",backgroundColor:"#E2E6EF",borderRadius:"8px",height:"35px",border:"none" }} onClick={propsConfirmCancel}>
              No
            </Button>
            
            <Button type="primary" style={{ fontFamily:"Roboto",fontWeight:700,width:"48%",marginLeft:"0.5em",borderRadius:"8px",height:"35px" }}  onClick={propsConfirmOk}>
              Yes
            </Button>
          </center>
          <br />
        </Modal>
      </div>
    </Fragment>
  );
};

export default RecordTitle;
